<template id="">
  <div>
    <h3>インテリジェントヘルスケア ストレスチェックApp</h3>
    <div
      v-show="
        !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
      "
    >
      <div class="tag-group">
        <ul>
          <li @click="$router.replace('/intelligent-company_info/' + com_id)">
            企業情報
          </li>
          <li @click="$router.replace('/intelligent-employee_list/' + com_id)">
            従業員一覧
          </li>
          <li v-bind:class="{ active: true }">ストレスチェック受診歴</li>
        </ul>
      </div>
      <table class="kigyo">
        <thead>
          <tr class="fixed01">
            <th></th>
            <th>依頼日</th>
            <th>実施開始日</th>
            <th>実施終了日</th>
            <th>有料区分</th>
            <th>状態</th>
            <th>報告書式</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(li, index) in stc_hst" :key="li.name">
            <td @click="get_targets_list(li.sc_id)">{{ index + 1 }}</td>
            <td @click="get_targets_list(li.sc_id)">{{ li.crt_date }}</td>
            <td @click="get_targets_list(li.sc_id)">
              {{ li.sc_answer_start_date }}
            </td>
            <td @click="get_targets_list(li.sc_id)">
              {{ li.sc_answer_end_date }}
            </td>
            <td @click="get_targets_list(li.sc_id)">
              {{ li.sc_group_analysis }}
            </td>
            <td @click="get_targets_list(li.sc_id)">{{ li.sc_status }}</td>
            <td @click="output_report(li.sc_id)">
              <a v-if="li.sc_status == 'ストレスチェック完了'"> 表示 </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div id="modal1" class="overlays" v-show="modal1_flg == true">
        <a class="cancel" @click="modal1_flg = !modal1_flg"></a>
        <div class="modals">
          <h2>受診者一覧</h2>
          <div class="cont">
            <table>
              <thead>
                <tr class="fixed01">
                  <th>従業員番号</th>
                  <th>名前</th>
                  <th>性別</th>
                  <th>生年月日</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="employee in targets_list" :key="employee.emp_code">
                  <td>{{ employee.emp_code }}</td>
                  <td>{{ employee.name }}</td>
                  <td>{{ employee.sex }}</td>
                  <td>{{ employee.birth_date }}</td>
                </tr>
              </tbody>
            </table>
            <p class="close">
              <a
                @click="
                  modal1_flg = !modal1_flg;
                  pdf_url = null;
                "
                >×</a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- <div id="modal2" class="overlays" v-show="modal2_flg == true">
        <a
          class="cancel"
          @click="
            modal2_flg = !modal2_flg;
            modal1_flg = !modal1_flg;
            pdf_url = null;
          "
        ></a>
        <div class="modals">
          <h2>受診者一覧</h2>
          <div class="cont">
            <iframe
              :src="pdf_url"
              width="950"
              height="800"
              v-if="pdf_url != null"
            ></iframe>
            <p v-else>表示するpdfがありません</p>
            <p class="close">
              <a
                @click="
                  modal2_flg = !modal2_flg;
                  modal1_flg = !modal1_flg;
                  pdf_url = null;
                "
                >×</a
              >
            </p>
          </div>
        </div>
      </div>
      <div id="modal3" class="overlays" v-show="modal3_flg == true">
        <a class="cancel" @click="modal3_flg = !modal3_flg"></a>
        <div class="modals">
          <h2>出力書式一覧</h2>
          <div class="cont">
            <table>
              <thead>
                <tr class="fixed01">
                  <th>書式名</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in display_name" :key="index">
                  <td @click="get_detail_output_pdf(index)">{{ data }}</td>
                </tr>
              </tbody>
            </table>
            <p class="close"><a @click="modal3_flg = !modal3_flg">×</a></p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      status: null,
      stc_hst: null,
      com_id: null,
      targets_list: null,
      sc_id: null,
      pdf_url: null,
      pdfs: [],
      do_something: true,
      tmp_sc_id: null,
      output: [],
      display_name: [],
      modal1_flg: false,
      modal2_flg: false,
      modal3_flg: false,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "ストレスチェック受診歴";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.status = this.$store.state.login_status_i;
    this.checkLogined();
    if (this.status) {
      this.get_stc_hst();
    }
  },
  methods: {
    // get_detail_output_pdf(index) {
    //   this.$axios
    //     .post("/intelligent/get_detail_output_pdf", {
    //       sc_id: this.tmp_sc_id,
    //       filename: this.output[index],
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       if (response.data.flg) {
    //         let data_uri_pdf = response.data.pdf;
    //         this.pdf_url = this.make_blob(data_uri_pdf);
    //         window.open(window.URL.createObjectURL(this.pdf_url));
    //       } else {
    //         console.log("pdfが存在しません");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    output_report(sc_id) {
      this.$axios
        .post("/intelligent/output_report", {
          sc_id: sc_id,
        })
        .then((response) => {
          if (response.data.flg) {
            window.open(
              window.URL.createObjectURL(this.make_blob(response.data.pdf))
            );
          }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    make_blob(base64_str) {
      let dataURI = "data:application/pdf;base64," + base64_str;
      // "iVBORw..."をバイナリに変換
      var byteString = atob(dataURI.split(",")[1]);

      // "image/png"
      var mimeType = dataURI.match(/(:)([a-z/]+)(;)/)[2];

      // バイナリからBlobを作成
      for (
        var i = 0, l = byteString.length, content = new Uint8Array(l);
        l > i;
        i++
      ) {
        content[i] = byteString.charCodeAt(i);
      }

      var blob = new Blob([content], {
        type: mimeType,
      });
      return blob;
      // window.open(window.URL.createObjectURL( blob )) ;
    },
    get_targets_list(sc_id) {
      this.sc_id = sc_id;
      this.$axios
        .post("/intelligent/get_targets_list", {
          sc_id: sc_id,
        })
        .then((response) => {
          if (response.data.flg) {
            console.log(response);
            this.targets_list = response.data.targets_list;
          }
          this.modal1_flg = true;
          this.do_something = true;
        })
        .catch((error) => {
          console.log(error);
          this.do_something = true;
        });
    },
    get_stc_hst() {
      this.com_id = this.$route.path.replace(
        "/intelligent-stress_check_history/",
        ""
      );
      this.$axios
        .post("/intelligent/get_stress_check_history", {
          com_id: this.com_id,
        })
        .then((response) => {
          if (response.data.flg) {
            this.stc_hst = response.data.stress_check_history;
          }
          this.$store.state.common.sDataLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.state.common.sDataLoading = false;
        });
    },
    checkLogined() {
      if (!this.status) {
        this.$router.push("/intelligent-login");
      }
    },
  },
};
</script>

<style scoped>
table {
  text-align: center;
  width: 100%;
}
table tbody tr:hover {
  background: #d4f0fd;
  cursor: pointer;
}

.fixed01 {
  position: sticky;
  top: -40px;
  color: #ffffff;
  background: #000000;

  &:before {
    /* content: ""; */
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
  }
}
.overlays {
  /* 要素を重ねた時の順番 */
  z-index: 1;

  /* 画面全体を覆う設定 */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  /* 画面の中央に要素を表示させる設定 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlays .cancel {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  cursor: default;
}

.overlays:target {
  visibility: visible;
  opacity: 1;
}

.modals {
  margin: 0px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #666;
  width: 80%;
  height: 90%;
  border-radius: 6px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modals h2 {
  margin-top: 0;
}

.modals .close {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  right: 20px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #777;
}

.modals .close:hover {
  opacity: 1;
}

.modals .cont {
  overflow-y: auto;
  max-height: 80vh;
}

.modals p {
  margin: 0 0 1em;
  text-align: center;
}

.modals p:last-child {
  margin: 0;
}

a.button:hover {
  box-shadow: 0 12px 23px rgba(0, 0, 0, 0.23), 0 10px 10px rgba(0, 0, 0, 0.19);
}

.overlays table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.overlays table tr {
  border-bottom: solid 1px #eee;
  cursor: pointer;
}

.overlays table tbody tr:hover {
  background-color: #d4f0fd;
}

.overlays table th,
.overlays table td {
  text-align: center;
  width: 10%;
  padding: 15px 0;
}
div {
  display: inline-block;
  width: 100%;
}

#data-area {
  max-height: 80vh;
  overflow-y: auto;
}

.tag-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  /* Fix for IE7 */
  *display: inline;
  /* Fix for IE7 */
}

ul {
  list-style: none;
  text-align: center;
}

.tab-group li {
  border: 1px solid #428bca;
  border-radius: 4px;
  position: relative;
  float: left;
  padding: 10px 15px 10px 15px;
}

.tab-group li:hover {
  background-color: #d4f0fd;
  cursor: pointer;
}

.active {
  background-color: #428bca;
  color: #fff;
}

.tab-group li + li {
  margin-left: -1px;
}

.tab-group > li:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.tab-group > li:first-child {
  margin-left: 0;
}

.tab-group > li:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.tab-group > li:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
table {
  text-align: center;
  width: 100%;
}
table tbody tr:hover {
  background: #d4f0fd;
  cursor: pointer;
}
</style>
